var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"align":"center","justify":"center"}},[_c('v-form',{ref:"checklistform",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitChecklist.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',{staticClass:"c-bg-gray"},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Approver Report")]),_c('v-spacer')],1),_c('v-container',{attrs:{"align":"center","justify":"center"}},[_c('v-row',{staticClass:"mx-auto py-4"},[_c('v-col',[_c('h1',[_c('span',{staticClass:"title c-blue-text"},[_vm._v(" Assessment center")]),_vm._v(" | "),_c('span',{staticClass:"title"},[_vm._v(" Bids Submitted")])])])],1),_c('v-row',[_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12","md":"12"}},[_c('v-card',{staticClass:"tw-px-2 md:tw-px-6 tw-mt-4 md:tw-mt-10",attrs:{"elevation":"2"}},[(!_vm.loading)?_c('div',[(_vm.bids !== null)?[_c('p',{staticClass:"c-gray-text ml-5 pt-8 tw-text-lg"},[_vm._v("Kindly analyse the bids and "),_c('span',{staticClass:"c-blue-text"},[_vm._v(" authorize by clicking the authorize button")])]),_c('v-tabs',{staticClass:"tw-my-4 md:tw-my-10",attrs:{"active-class":"tab-active","background-color":"primary","center-active":"","color":"warning","grow":"","dark":"","show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{staticClass:"tw-p-3",attrs:{"color":"warning"}}),_c('v-tab',[_vm._v("Assessment Report")]),_c('v-tab',[_vm._v(" "+_vm._s(_vm.scanObject(_vm.garage, 'name')))])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('AssessmentReport',{attrs:{"is-garage":false,"booking":{
                          biddingForm: _vm.biddingTemplate,
                          ..._vm.bidInfo,
                        }}})],1),_c('v-tab-item',[_c('garage-bid-template',{attrs:{"garageInfo":_vm.garageInfo,"booking":{
                          biddingForm: _vm.biddingTemplate,
                          ..._vm.bidInfo,
                        },"isGarage":false,"approving":_vm.approveLoader},on:{"authorize-bid":_vm.authorizeBooking}})],1)],1)]:_c('div',{staticClass:"tw-py-4 md:tw-py-14 tw-flex tw-items-center tw-justify-center tw-flex-col"},[_c('img',{staticClass:"tw-h-16 md:tw-h-28",attrs:{"src":require('@/assets/img/assesment-center/no-car.png'),"alt":"no car"}}),_c('p',{staticClass:"tw-w-full md:tw-w-1/3 tw-text-xs tw-p-3 tw-text-justify"},[_vm._v(" This bid is "),_c('span',{staticClass:"c-blue-text tw-font-bold"},[_vm._v("currently unavailable.")])])])],2):_c('div',{staticClass:"tw-w-full tw-flex tw-justify-center tw-py-16"},[_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}})],1)])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }